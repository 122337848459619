import styled from 'styled-components';
import arrowPattern from '../../../images/Arrow_Pattern.png';

export default styled.div`
  background-color: #080809;
  height: 100vh;

  & .heroImage {
    width: 100%;
    height: auto;
  }

  .pattern {
    width: 100%;
    height: 67px;
    background-image: url(${arrowPattern});
    background-size: 100%;
    background-repeat: no-repeat;
  }
`;
